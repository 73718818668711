<template>
<div>
  <!-- <h1 class=""> {{ warehouse.current_shelve + 1 }}</h1> -->
  <div 
    class="shelf_wrapper"
    :id="`shelf_wrapper_${shelfIndex}`"
    :class="highlighted.shelf === shelfIndex.toString() ? 'highlight' : ''"
    >
  <div class="shelf_badge_wrapper">
    <div class="shelf_badge">
      <v-chip
        small
        color="primary"
        >
        Shelf {{ shelfIndex | LetterFilter }}
      </v-chip>
    </div>
  </div>
  <div 
    @mouseleave="removeButtons" 
    @mouseover="killTimer" 
    class="shelf pt-6" 
    >
    <div
      @mouseover="hovering(i)" 
      v-for="(slot, i) in shelf.slots" 
      :key="`slot-${i}`" 
      class="product_slot" 
      :style="{width: `${100 / shelf.slots.length}%`}"
      :id="highlighted.shelf === shelfIndex.toString() && highlighted.slot === i.toString() ? 'highlighted_slot' : ''"
      >
      <!-- :cols="productWidth" -->
      <div class="badge_wrapper">
        <div class="badge_container">
          <!-- <v-text-field x-small value="A" max-width="20px"/> -->
          <v-chip
            small
            outlined
            color="primary"
            >
            Slot {{ i + 1 }}
          </v-chip>
        </div>
      </div>
        <div 
          class="slot_wrapper" 
          v-if="showButtons === `slot-${i}`"
          > 
          <!-- v-if="showButtons === `slot-${i}`"  -->
          <div class="add_slot_container">
              <v-btn fab x-small color="secondary" :disabled="shelf.slots.length === 1 && warehouse.shelves[warehouse.current_shelve].shelfs.length === 1" @click="removeSlot(i)" class="badge">
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-btn fab x-small color="primary"  @click="addSlot(i)" class="badge ml-1">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
          </div>
        </div>
      <div v-for="(product, i2) in slot.products" :key="`y-${i2}`" @mouseover="hoverProduct(i,i2)">
        <div 
          :class="highlighted.shelf === shelfIndex.toString() && highlighted.slot === i.toString() && highlighted.product === i2.toString() ? 'highlighted' : ''"
          class="productSelect"
          >
          <v-autocomplete
            :items="unshelfed_products(shelfIndex, i, i2)"
            item-text="title"
            item-value="uuid"
            small-chips
            single-line
            dense
            hide-details
            clearable
            v-model="warehouse.shelves[warehouse.current_shelve].shelfs[shelfIndex].slots[i].products[i2].uid"
            class="my-4"
            @change="change_product({ shelfIndex, slotIndex: i, productIndex: i2 })"
            ></v-autocomplete>
            <!-- <v-chip 
              close
              close-icon="mdi-close"
              @click:close="remove_product({ shelfIndex, slotIndex: i, productIndex: i2 })"
              class="mt-4"
              v-else
              >
              {{ get_product(warehouse.shelves[warehouse.current_shelve].shelfs[shelfIndex].slots[i].products[i2].uid) && get_product(warehouse.shelves[warehouse.current_shelve].shelfs[shelfIndex].slots[i].products[i2].uid).title || 'Unknown' }}
            </v-chip> -->
        </div>
        
          <div class="productButtons">
            <template v-if="showProductButtons === `slot-${i}-product-${i2}`">
              <v-btn width="20px" height="20px" x-small fab elevation="1" color="primary" @click="addProduct(i, i2)">
                <v-icon dark>
                mdi-plus  
                </v-icon>
              </v-btn>
              <v-btn 
                width="20px" 
                height="20px" 
                x-small 
                fab 
                elevation="1" 
                color="secondary" 
                class="mt-0" 
                @click="removeProduct(i, i2)"
                :disabled="slot.products.length === 1 && shelf.slots.length === 1 && warehouse.shelves[warehouse.current_shelve].shelfs.length === 1" 
                >
                <v-icon dark>
                mdi-minus
                </v-icon>
              </v-btn>
            </template>
          </div>
        
      </div>

      



    </div>
  </div>
  </div>
  <v-row class="my-4">
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        x-small
        outlined
        color="secondary"
        @click="add_shelf(shelfIndex)"
      >
        Add shelf
      </v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { LetterFilter } from '@/utils/filters'
  import { uid } from 'uid'

  import VueScrollTo from 'vue-scrollto'

  export default {
    name: 'ShelfComponent',
    data: () => {
      return {
        showButtons: '',
        hoverRow: null,
        showProductButtons: '',
        highlighted: {},
      }
    },
    filters: {
      LetterFilter,
    },
    props: {
      shelfIndex: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    mounted(){
      this.highlighted = this.$route.query;
      if(this.highlighted && this.highlighted.shelf === this.shelfIndex.toString()){
        this.$nextTick( () => VueScrollTo.scrollTo(`#highlighted_slot`, 1500, {
            container: `#shelf_wrapper_${this.shelfIndex}`,
            x: true,
            y: false,
            offset: -60,
        }))
      }
    },
    methods: {
      remove_product({ shelfIndex, slotIndex, productIndex }){
        this.warehouse.shelves[this.warehouse.current_shelve].shelfs[shelfIndex].slots[slotIndex].products[productIndex].printed = false
        this.warehouse.shelves[this.warehouse.current_shelve].shelfs[shelfIndex].slots[slotIndex].products[productIndex].uid = ''
        this.$store.commit('warehouse/CHANGE_SHELF', shelfIndex)
      },
      add_shelf(shelfIndex){
        this.$store.commit('warehouse/ADD_SHELF', shelfIndex)
        this.$store.commit('warehouse/SET_UNPRINTED_PAST', { 
          shelve: {
            from: this.warehouse.current_shelve,
            to: this.warehouse.current_shelve + 1,
            },
          shelf: {
            from: shelfIndex + 1
          } 
          })
      },
      change_product({ shelfIndex, slotIndex, productIndex }){
        this.warehouse.shelves[this.warehouse.current_shelve].shelfs[shelfIndex].slots[slotIndex].products[productIndex].printed = false
        this.$store.commit('warehouse/CHANGE_SHELF', shelfIndex)
      },
      hovering(i){
        this.showButtons = `slot-${i}`
      },
      hoverProduct(i, i2){
        this.showProductButtons = `slot-${i}-product-${i2}`
      },
      killTimer(){
        if(this.hoverRow) clearTimeout(this.hoverRow)
      },
      removeButtons(){
        // this.hoverRow = setTimeout(() => {
        //   this.showProductButtons = ''
        //   this.showButtons = ''
        //   }, 1)
          this.showProductButtons = ''
          this.showButtons = ''
      },
      // METHODS THAT WILL ALTER SHELF
      removeSlot(i){
        this.shelf.slots.splice(i, 1)
        // this.$emit('change', this.shelf)
        if(!this.shelf.slots.length) {
          this.$store.commit('warehouse/REMOVE_SHELF', this.shelfIndex)
          this.$store.commit('warehouse/SET_UNPRINTED_PAST', { 
            shelve: {
              from: this.warehouse.current_shelve,
              to: this.warehouse.current_shelve + 1,
              },
            shelf: {
              from: this.shelfIndex
            } 
            })
          }
        else {
          this.$store.commit('warehouse/CHANGE_SHELF', this.shelfIndex)
          this.$store.commit('warehouse/SET_UNPRINTED_PAST', { 
            shelve: {
              from: this.warehouse.current_shelve,
              to: this.warehouse.current_shelve + 1,
              },
            shelf: {
              from: this.shelfIndex,
              to: this.shelfIndex + 1,
            },
            slot: {
              from: i
            }
            })
          }
        this.touch()
      },
      addSlot(i){
        this.shelf.slots.splice(i + 1, 0, {id: uid(), products: [{uid: '', id: uid() }]})
        // this.$emit('change', this.shelf)
        this.$store.commit('warehouse/CHANGE_SHELF', this.shelfIndex)
                  this.$store.commit('warehouse/SET_UNPRINTED_PAST', { 
            shelve: {
              from: this.warehouse.current_shelve,
              to: this.warehouse.current_shelve + 1,
              },
            shelf: {
              from: this.shelfIndex,
              to: this.shelfIndex + 1,
            },
            slot: {
              from: i + 1
            }
            })
        this.touch()
      },
      addProduct(i, i2){
        this.shelf.slots[i].products.splice(i2 + 1, 0, {uid: '', id: uid() })
        // this.$emit('change', this.shelf)
        this.$store.commit('warehouse/CHANGE_SHELF', this.shelfIndex)
        this.$store.commit('warehouse/SET_UNPRINTED_PAST', { 
            shelve: {
              from: this.warehouse.current_shelve,
              to: this.warehouse.current_shelve + 1,
              },
            shelf: {
              from: this.shelfIndex,
              to: this.shelfIndex + 1,
            },
            slot: {
              from: i,
              to: i + 1,
            },
            product: {
              from: i2 + 1
            }
            })
        this.touch()
      },
      removeProduct(i, i2){
        this.shelf.slots[i].products.splice(i2, 1)
        if(!this.shelf.slots[i].products.length) this.removeSlot(i)
        else this.$store.commit('warehouse/SET_UNPRINTED_PAST', { 
            shelve: {
              from: this.warehouse.current_shelve,
              to: this.warehouse.current_shelve + 1,
              },
            shelf: {
              from: this.shelfIndex,
              to: this.shelfIndex + 1,
            },
            slot: {
              from: i,
              to: i + 1,
            },
            product: {
              from: i2, 
            }
            })

        this.$store.commit('warehouse/CHANGE_SHELF', this.shelfIndex)
        this.touch()
      },
      touch(){
        this.highlighted = {}
      }
    },
    computed: {
      ...mapState({
        shelf(state) {
          return state.warehouse.shelves[state.warehouse.current_shelve].shelfs[this.shelfIndex]
        },
        warehouse: state => state.warehouse,
      }),
      ...mapGetters({
        'unshelfed_products': 'warehouse/unshelfed_products',
        'get_product': 'product/get_product',
        })
    },
  }
</script>

<style lang="scss" scoped>
.slot_wrapper {
  position: relative;
  width: 100%;
  // min-width: 200px !important;
  right: 0px;
}


.add_slot_container {
  position: absolute;
  top: -40px;
  right: -58px;
  // top: -34px;
  // right: -68px;
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  width: 124px;
  max-width:124px;
}


.badge {
  // top: -30px;
  // z-index: 99;
  // left:0px;
  display: inline-block;
  // position:fixed;
}
.badge_container {
  position: absolute;
  // z-index: 9999999999;
  top: -20px;
  left: 0px;
  min-width: 200px;
  // height: 140px;
  // left:0px;
}


.badge_wrapper {
  position: relative;
  width: 0px;
  height: 0px;
  // top: -30px;
  // left: 0px;
}

.productButtons {
  // overflow-x: scroll;
  width: 20px;
  margin-left: 5px;
  display: inline-block;
}

@keyframes borderflash { 
    from, to {    
        background: none;
    }    
    50% {    
        background-color: rgba(80, 143, 7, 0.5)
    }    
}  

@keyframes shelf_borderflash { 
    from, to {    
        border: 1px solid black;
    }    
    50% {    
        border: 1px solid rgba(80, 143, 7, 0.5)
    }    
}  

.productSelect {
  display: inline-block;
  width: calc(100% - 30px);
  border-radius: 10px;
  &.highlighted {
    animation: borderflash 1s 6;
  }
}  

.shelf_badge_wrapper {
  position: absolute;
  
  
}

.shelf_badge {
  // left: 0px;
  -webkit-transform: translate(0%,-115%);
  transform: translate(0%,-115%);
  // top: 50%;
  // position: absolute;
}

.shelf {
  display: flex;
  flex-direction: row;
  // display: inline-flex !important;
  // flex-wrap: nowrap !important;
  background: linear-gradient(90deg,rgba(255,255,255,1),rgb(218, 218, 218));
  min-width: 100%;
  margin: 0 0;
  padding: 0 10;
  .product_slot {
    // display: inline-block;
    // position: absolute; 
    // min-width: 200px !important;
    // align-self: top;
    .slot_wrapper {
    }
  }
}

.shelf_wrapper {
  border: 1px solid black;
  border-radius: 5px;
  // overflow-x: scroll;
  background-color:rgb(218, 218, 218);
  &.highlight {
    // border: 4px solid rgba(143, 55, 7, 0.5);
    animation: shelf_borderflash 1s 6
    // background: linear-gradient(90deg, rgba(80, 143, 7, 0.2), rgba(39, 39, 30, 0.2), rgba(255,255,255,1));
  }
}

</style>
